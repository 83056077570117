<div class="w-[80px] h-[48px] text-accent-700 flex cursor-pointer select-none border-none items-center justify-center"
     (click)="toggleMenu()">
	<span class="material-icons">menu</span>
</div>

<div class="mt-[40px] px-[12px] pt-0 pb-[12px] flex-1 flex flex-col">
	<div class="py-[18px] px-[16px] min-h-[20px] menu-collapsable-item cursor-pointer">
		<p class="paragraph-primary" [class.closed]="!isExpanded()">{{ title() }}</p>
	</div>

	@for (menuItem of menuItems(); track $index) {
    @if (menuItem.submenu?.length > 0) {
      <div class="flex flex-row relative">
        <div
          class="menu-item p-[16px] w-full hover:bg-accent-100 menu-collapsable-item cursor-pointer"
          (click)="menuTrigger.openMenu()"
          [class.expanded]="isExpanded()">
          <span class="active:text-surface-white material-symbols-outlined">{{ menuItem.icon }}</span>
          <p class="active:text-surface-white paragraph-secondary"
             [class.closed]="!isExpanded()">{{ menuItem.text | translate }}</p>
        </div>
        <div class="absolute h-[1px] w-0 right-[-24px]" #menuTrigger="matMenuTrigger"
             [matMenuTriggerData]="{data: menuItem.submenu, title: menuItem.text, icon: menuItem.icon}"
             [matMenuTriggerFor]="submenu"></div>
      </div>
    } @else {
      <div
        class="menu-item p-[16px] hover:bg-accent-100 menu-collapsable-item cursor-pointer"
        routerLinkActive="active"
        [routerLink]="[menuItem.url]"
        [class.expanded]="isExpanded()">
        <span class="active:text-surface-white material-symbols-outlined">{{ menuItem.icon }}</span>
        <p class="active:text-surface-white paragraph-secondary" [class.closed]="!isExpanded()">{{ menuItem.text | translate }}</p>
      </div>
    }
	}
</div>

<div class="menu-bottom-panel p-[12px]">
	<div class="border-bottom-logout relative p-[16px] rounded-none menu-collapsable-item cursor-pointer"
	     (click)="onLogoutClick()"
	     [class.expanded]="isExpanded()">
		<span class="material-icons">logout</span>
		<p class="paragraph-secondary" [class.closed]="!isExpanded()">Logout</p>
	</div>
	<div class="bottom-version p-[16px] menu-collapsable-item"
	     [class.expanded]="isExpanded()">
		<p class="paragraph-tertiary w-full text-center">{{ version() }}</p>
	</div>
</div>

<mat-menu #submenu="matMenu">
  <ng-template let-data="data" let-icon="icon" let-title="title" matMenuContent>
	  <div class="p-6 justify-start items-center gap-4 inline-flex">
		  <div class="w-6 justify-center items-center flex">
			  <div
				  class="w-6 h-6 menu-item-highlight relative flex-col justify-start items-start flex active:text-surface-white material-symbols-outlined">{{ icon }}</div>
		  </div>
		  <div class="grow shrink basis-0 text-stone-900 text-[22px] font-semibold leading-7">{{title | translate}}</div>
	  </div>
    <div class="pb-4">
      @for(submenuItem of data; track $index) {
        <button mat-menu-item
                routerLinkActive="menu-item-highlight"
                [attr.test-id]=submenuItem.url
                [routerLink]=submenuItem.url>
          <span class="text-base font-normal leading-normal tracking-wide">{{submenuItem.text | translate}}</span>
        </button>
      }
    </div>
  </ng-template>
</mat-menu>